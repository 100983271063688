.package-card {
    width: 350px;
    height: auto;
    color: hsl(229, 23%, 23%);
    font-family: "Urbanist", sans-serif;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    margin: 1rem 0;
}

.image {
    width: 100%;
    height: 200px;
}

.image img {
    width: 100%;
    height: 100%;
}
.content {
    padding: 8px 16px;
}

.rating {
    display: flex;
    padding-top: 1rem;
    align-items: center;
}

.package-name {
    font-size: 1.5rem;
    padding-top: 1rem;
    font-weight: 700;
}
.package-info {
    font-size: 1rem;
    padding-top: 1rem;
    font-weight: 400;
    letter-spacing: 0.1px;
}

.trip-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 0 1rem 0;
}

.trip-data-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 800;
}

.price {
    margin: 1rem 2rem 1rem 0;
    text-align: end;
    display: flex;
    align-items: center;
}
.price1 {
    font-size: 1.4rem;
    text-decoration: line-through;
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
}
.price2 {
    font-size: 1.5rem;
    font-weight: 600;
}

.go-btn {
    text-align: center;
    /* padding: 4px 8px; */
}

.gobtn {
    /* margin: 0.5rem 0; */
    background-color: #d5b15b;
    font-family: "Urbanist", sans-serif;
    font-weight: 800;
    border: none;
    outline: none;
    font-size: 1.2rem;
    /* border-radius: 5px; */
    cursor: pointer;
    letter-spacing: 1px;
    padding: 0.8rem;
    width: 100%;
  }
  .gobtn:hover {
    background-color: #d5b15b;
    color: #fff;
    transition: 0.2s ease;
    font-weight: 500;
  }

  @media (max-width: 480px) {
    .package-card {
        width: 90%;
    }
  }