.snackbar {
	width: 100%;
	height: 5.5vh;
	color: black;
	background-color: #d5b15b;
	text-align: center;
	font-size: 1rem;
	padding: 1% 0 0 0;
	vertical-align: middle;
	/* align-items: center; */
}

/* .text {
	margin-bottom: 20px;
} */


@media (max-width: 480px) {
    .snackbar {
        height: 7.5vh;
    }
  }