/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of other content */
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 4px;
    position: relative;
  }
  
  /* Prevent body from scrolling when modal is open */
  .no-scroll {
    overflow: hidden;
  }

  @media (max-width: 600px) {
    .modal-content {
      width: 90%; /* Make the modal narrower on small screens */
    }
  }
  
  