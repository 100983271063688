.faq-list-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 40px auto;
    font-weight: 400;
}
.faq-list-container p{
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
}
.faq-list-container h2 {
    margin-bottom: 40px;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 600;
}

.question {
    font-size: 1rem;
    font-weight: 450;
    padding: 0.5rem 0;
}
.answer {
    padding: 0.5rem 1rem;
    font-size: 1rem;
}
.answer span{
    font-weight: 500;
}

@media (max-width: 480px) {
    .faq-list-container {
        width: 100%;
        padding: 1rem 1rem;
        margin-top: 0;
    }
    .faq-list-container h2{
        margin-bottom: 20px;
    }
}