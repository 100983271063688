.testimonial-card {
    position: relative;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 40px auto;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .photo-container {
    position: absolute;
    top: -30px; /* Adjust this value to control the overlap */
    left: 20px; /* Adjust based on your layout/design preferences */
    width: 60px; /* Size of the photo */
    height: 60px; /* Make sure this matches the width for a circle */
    border-radius: 50%; /* Creates the circle shape */
    overflow: hidden;
    border: 3px solid #fff; /* Optional: adds a border around the photo */
  }
  
  .photo-container img {
    width: 100%;
    height: auto;
  }
  
  .text-block {
    padding-top: 40px; /* Make room for the overlapping photo */
    text-align: left;
  }
  
  .author {
    font-weight: bold;
    margin-top: 10px;
  }
  