.trip-date {
	max-width: 100%;
	/* margin: 40px auto; */
}
.collapse {
	overflow: scroll;
	margin-bottom: 40px;
	padding: 20px 0;
}

.collapse > :not(:last-child) {
	margin-bottom: 0px;
}

.collapse_summary div {
	margin: 0 !important;
}

.collapse_header {
	display: flex;
	align-items: center;
	height: 60px;
	font-size: 1rem;
}


.red{
	color: red;
}

.back-yello{
	background-color: rgb(255, 248, 109);
}

.collapse_day {
	background: #f9f9f9;
	color: #000;
	font-size: 1.3rem;
	font-weight: 800;
	height: 100%;
	width: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.collapse_heading {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-start;
	padding: 5rem;
}


.collapse_heading_text {
	font-size: 1rem;
	font-weight: 600;
	line-height: 120%;
}

.collapse_details {
	padding: 0;
}

.razorpay-button-container{
	border-radius: 50px;
}

.collapse_heading_right {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	/* align-items: flex-end; */
	padding: 3rem;
	align-items: center;

}
.right-div{
	padding-left: 160px;
}



@media (max-width: 480px) {
	.itinarary-content {
		width: 100%;
		padding: 0;
	}
	.itinarary-gallary {
		width: 100%;
		padding: 0;
	}
	.day-details {
		display: flex;
		flex-direction: column;
		padding: 24px 16px;
	}
	.place-details {
		padding: 0;
	}

    .collapse_header {
        font-size: 0.7rem;
    }
    .collapse_heading_text{
        font-size: 0.7rem;
    }
    .collapse_day {
        font-size: 0.7rem;
    }
	.collapse_heading{
		padding: 1rem;
	}
	.collapse_heading_right{
		padding: 1rem;
	}
}

/* @media (min-width: 480px) {
	.trip-itinarary-mobile {
		display: none;
	}
} */