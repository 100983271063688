.trip-highlights-container {
  /* max-width: 1200px; */
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 20px;
}

.trip-highlights-container h2 {
  margin: 20px;
  font-size: 1.8rem;
  font-weight: 600;
}
.trip-highlights-container h3 {
  font-size: 1rem;
  margin-bottom: 20px;
}

.carousel {
  display: flex;
  overflow-x: scroll;
}
.carousel::-webkit-scrollbar{
  display: none;
}

.carousel-item {
  flex: 0 0 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  position: relative;
  height: auto;
}

.carousel-item img {
  width: 100%;
  height: 250px;
  display: block;
}

.carousel-item h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: 400;
}

.carousel-item p {
  margin-bottom: 1em;
  padding: 0 20px;
}

.status-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: red;
  color: black;
  padding: 5px;
  border-radius: 5px;
}

.status-tag.included {
  background-color: #d5b15b;
  font-size: 1em;
}

.status-tag.additional-cost {
  background-color: orange;
}

/* Responsive styles */
@media (max-width: 768px) {
  .carousel-item {
    flex: 0 0 50%; /* Each item takes up half of the container's width on medium screens */
  }
}

@media (max-width: 480px) {
  .trip-highlights-container {
    width: 100%;
  }
  .carousel-item {
    flex: 0 0 85%; /* Each item takes up the full width on small screens */

  }
  .carousel-item h3{
    font-size: 1rem;
  }

  .trip-highlights-container h2 {
    margin: 20px;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .trip-highlights-container h3 {
    font-size: 1rem;
    margin-bottom: 20px;
  }
}

