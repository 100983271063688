.stats-container {
  justify-content: space-around;
  padding: 3%;
  flex-wrap: wrap; /* Wrap items on smaller screens */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 3%;
}

.stat-card {
  text-align: center;
  margin: 10px;
}

.number {
  font-size: 3em;
  font-weight: bold;
  color: #d5b15b; /* Example color */
}

.label {
  font-size: 1em;
  margin-top: 5px;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .stats-container {
    grid-template-columns: 1fr;
  }

  .stat-card {
    margin-bottom: 20px;
  }
}
