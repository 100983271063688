/* BlogCard.css */
.blog-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  max-width: 300px; /* Adjust the width as necessary */
  height: 100%;
  cursor: pointer;
}

.blog-image-container {
  width: 100%;
  height: auto;
}

.blog-image {
  width: 100%;
  height: auto;
  display: block;
}

.blog-content {
  padding: 6%;
}

.blog-category {
  font-weight: bold;
  text-transform: uppercase;
  color: #555;
}

.blog-title {
  margin-top: 5%;
  font-size: 1.3rem;
  color: #333;
}

.blog-author {
  color: #777;
  font-size: 0.9em;
  /* margin-bottom: 5px; */
  margin-top: 4%;
}

.blog-read-time {
  background-color: #ededed;
  padding: 5px 10px;
  border-radius: 15px;
  display: inline-block;
  font-size: 0.8em;
  color: #555;
  margin-top: 8%;
}

@media (max-width: 768px) {
  .blog-card {
    max-width: 100%;
  }
}
