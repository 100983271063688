.reachUs {
    width: 80%;
    margin-top: 40px;
    margin: auto;
    /* padding: 2rem 3rem; */
    text-align: center;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  */
}

.reachUs h2 {
    /* margin-top: 40px; */
    margin-bottom: 40px;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
}
.optionsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.optionCard {
    flex-basis: calc(50% - 20px);
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    /* cursor: pointer; */
    background-color: #fff; /* Ensure readability with a solid background */
}

.optionCard:hover, .optionCard:focus {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.reachUs h3 {
    color: #333;
    font-size: 1.3rem; /* Subheading font size */
    margin-bottom: 1rem;
    letter-spacing: 0.2px;

}

.reachUs p {
    color: #666;
    font-size: 1rem; /* Paragraph font size, for readability */
    line-height: 1.6; /* Improved line height for better reading experience */
    margin-bottom: 2rem;
    letter-spacing: 0.1px;

}

/* Responsive Typography */
@media (max-width: 768px) {
    .optionCard {
        flex-basis: calc(100% - 20px);
    }

    h2 {
        font-size: 1.75rem; /* Slightly smaller font size for smaller screens */
    }

    h3 {
        font-size: 1.25rem;
    }

    p {
        font-size: 0.9rem; /* Adjust paragraph font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .reachUs {
        width: 100%;
        padding: 2rem 1rem;
    }
    h2 {
        font-size: 1.5rem; /* Further reduce for very small screens */
    }

    h3 {
        font-size: 1.1rem;
    }

    p {
        font-size: 1rem;
    }
}
