.home {
	height: 86vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	position: relative;
	z-index: 1;
}

.section-title {
	font-size: 2em;
	margin-bottom: 30px;
	text-align: center;
}

.home .content {
	position: absolute;
	top: 25%;
	background-color: rgba(0, 0, 0, 0.4);
	padding: 1.5rem;
	box-shadow: 0 8px 39px 17px rgba(0, 0, 0, 0.8);
	text-align: center;
	border-radius: 10px;
}
.home .content h3 {
	color: #fff;
	text-shadow: 3px 3px rgb(199 200 205 / 37%);
	/* text-shadow: 2px 2px 4px #0f0f0f; */
	font-size: 4.5rem;
	text-transform: capitalize;
	letter-spacing: 3px;
	font-weight: 300;
	font-family: "Montserrat", sans-serif;
}
.home .content p {
	color: #fff;
	font-family: "Urbanist", sans-serif;
	letter-spacing: 2px;
	text-shadow: 1px 2px rgb(199 200 205 / 37%);
	margin: 1rem 0;
	font-size: 1.2rem;
}
.home .video-container {
	width: 100%;
	height: 100%;
}
.home .video-container video {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	height: 100%;
	width: 100%;
	object-fit: cover;
	transition: 0.3s ease;
}

.home .controls {
	padding: 1rem;
	display: inline-block;
	border-radius: 1rem;
	background-color: rgba(0, 0, 0, 0.5);
	top: 10%;
	position: relative;
}
.home .controls .vid-btn {
	height: 0.7rem;
	width: 0.7rem;
	display: inline-block;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
	margin: 0 0.5rem;
}

.home .controls .vid-btn.active {
	background: #d5b15b;
}

.info_container {
	width: 100%;
	display: flex;
	justify-content: space-around;
	background-color: rgba(0, 0, 0, 0.5);
	font-family: "Urbanist", sans-serif;
	padding: 1rem;
}

.info_element {
	display: flex;
	align-items: center;
	gap: 1rem;
	text-decoration: none;
}

.info_icon {
	margin-top: 4px;
	color: #d5b15b;
}

.info_text {
	color: white;
}

.exp-btn {
	margin: 1rem 0;
	background-color: #d5b15b;
	font-family: "Urbanist", sans-serif;
	-webkit-appearance: none;
	font-size: 1.1rem;
	border: none;
	outline: none;
	border-radius: 10px;
	cursor: pointer;
	letter-spacing: 2px;
	padding: 1rem;
	-moz-appearance: none;
}

.exp-btn:hover {
	background-color: #37474f;
	color: #fff;
	transition: 0.2s ease;
}

#diff {
	color: #d5b15b;
}

@media only screen and (max-width: 600px) {
	.home .content h3 {
		font-size: 3.5rem;
	}
	.home .content p {
		font-size: 1rem;
	}
}

@media (max-width: 480px) {
	.content {
		box-shadow: none !important;
		border-radius: 0 !important;
	}

	.info_container {
		flex-wrap: wrap;
		row-gap: 0.5rem;
	}

	.info_element {
		gap: 0.8rem;
	}
}
