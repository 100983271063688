@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

*,
::after,
::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
}
:root{
    --dark: #282828;
    --red-500: #AF143E;
    --purple-50: #F0EBFA;
    --purple-500: #47268D;
    --orange-50: #FCF3E7;
    --orange-500: #EDAB56;
    --gray-50: #F3F3F3;
    --gray-100: #eee;
    --gray-200: #ddd;
    --gray-600: #888;
    --green-50: #D9EDED;
    --green-500: #186B6D;
    --green-600: #135657;
    --white: #fff;
}
/* Footer Start*/
footer {
  padding: 64px;
  background-color: var(--dark);
  color: white;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--white);
}
/* .logo {
  padding-left: 1rem;
} */
.footer-logo a span {
  color: #d5b15b;
}
.footer-logo a {
  display: flex;
  /* padding-left: 1rem; */
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 2rem;
  font-weight: 600;
  color: #fff;
  font-family: "Urbanist", sans-serif;
}
.footer-logo p {
  color: #fff;
  font-size: 0.7rem;
  word-spacing: 0.2rem;
  /* padding-left: 1rem; */
  font-family: "Urbanist", sans-serif;
  letter-spacing: 4px;
  text-align: left;
}

.footer-logo img {
  width: 100px;
}
.footer-social {
  display: flex;
  align-items: center;
}
.footer-social > *{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-left: 10px;
  font-size:24px;
  background-color: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #d5b15b;
}
.footer-links{
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0;
}
.footer-links > * {
  margin-right: 10%;
  margin-bottom: 20px;
}
.footer-links-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 14px;
}
.footer-links-list {
  list-style-type: none;
}
.footer-links-list li {
  margin-bottom: 13px;
}
.footer-links-list li a {
  text-decoration: none;
  color: white;
}
.footer-bottom{
  padding: 24px 0;
  border-top: 1px solid var(--white);
}
/*Footer End*/

@media screen and (max-width: 600px){
  .footer-logo {
    margin-bottom: 20px;
  }
  .footer-top{
    display: flex;
    flex-direction: column;
  }
  .footer-top{
    margin-bottom: 20px;
  }
}