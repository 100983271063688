/* TestimonialsContainer.css */
.testimonials-container {
	display: flex;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	/* -webkit-overflow-scrolling: touch; For momentum scrolling on iOS */
	padding: 20px 20%;
	margin: auto;
	width: 85%;
	scroll-behavior: smooth;
}
.section-title-test {
	font-size: 1.8em;
	text-align: center;
	padding-top: 4%;
	font-weight: 600;
}

.testimonials-scroll-container {
	display: flex;
	gap: 20px;
	align-items: stretch;
}

.testimonials-scroll-container::-webkit-scrollbar {
	display: none; /* Hide scrollbar for webkit browsers */
}

.testimonial-card {
	scroll-snap-align: start;
}

.section-title {
	justify-content: center;
	display: flex;
}
/* .d-flex {
    display: flex;
    transform: translateX(calc(100vw));
    overflow: visible;
    animation: animateContainer 10s linear forwards infinite;
    animation-play-state: paused;
  }
  @keyframes animateContainer {
    from {
      transform: translateX(calc(100vw));
    }
    to {
      transform: translateX(calc(-100%));
    }
  } */

@media (max-width: 768px) {
	/* Adjust styles for mobile responsiveness */
}
