.formContainer {
  max-width: 500px;
  max-height: 690px;
  margin: 2% auto;
  padding: 2%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  text-align: center;
  color: #333;
  font-size: 20px;
}

.instructions {
  font-size: 15px;
  text-align: center;
  color: #666;
  margin-bottom: 30px;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 8px;
  color: #333;
}

.input,
.textarea {
  font-size: 1rem;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.input:focus,
.textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0,123,255,.25);
}

.textarea {
  min-height: 100px;
  resize: vertical;
}

/* .button {
  padding: 15px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
} */

.button:hover {
  background-color: #0056b3;
}

/* Responsive styling */
@media (max-width: 768px) {
  .formContainer {
    width: 90%;
    max-height: 1%;
    /* margin: 2% auto; */
  }
  .input,
  .textarea {
    font-size: 0.9rem;
  }
}
