.about-us-container {
  background-color: #fff;
  padding: 50px;
  color: #000;
  display: flex;
  justify-content: space-between;
}

.about-us-title {
  font-size: 48px;
  margin-bottom: 30px;
  position: relative;
}

.about-us-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 50px;
  height: 4px;
  background-color: #ffa500;
}
.about-us-text-section {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.about-us-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
}

.about-us-text {
  /* width: 60%; */
  font-size: 18px;
  line-height: 1.6;
}

.about-us-image {
  width: 45%;
  position: relative;
}

.about-us-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-us-container {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0;
  }
  .about-us-text-section {
    width: 100%;
    margin-top: 40px;
  }
  .about-us-content {
    flex-direction: column;
    align-items: center;
  }

  .about-us-text,
  .about-us-image {
    width: 100%;
  }

  .about-us-title {
    font-size: 36px; /* Smaller font size for smaller screens */
  }

  .about-us-text {
    margin-bottom: 20px; /* Add space between text and image on small screens */
  }
}

/* Further adjustments for smaller screens */
@media (max-width: 480px) {
  .about-us-title {
    font-size: 28px; /* Even smaller font size for very small screens */
  }

  .about-us-container {
    padding: 20px;
  }
}
