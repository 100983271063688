.whatsapp-button{
  position: fixed;
  bottom: 80px; /* Adjust the distance from bottom as per requirement */
  right: -5px; /* Adjust the distance from right as per requirement */
  z-index: 9999; /* Ensure it's above other content */
}

.whatsapp-link {
  display: block;
  width: 60px; /* Adjust the size of the WhatsApp button */
  height: 60px; /* Adjust the size of the WhatsApp button */
  border-radius: 50%; /* Make it round */
  /* background-color: #d5b15b; WhatsApp green color */
  text-align: center;
  line-height: 60px;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); Add shadow for better visibility */
}

.whatsapp-link:hover {
  /* background-color: #128c7e; Darker shade on hover */
}

.whatsappicon i {
  font-size: 40px;
  color: #d5b15b;
  /* outline: none;
  border: none;
  background-color: transparent; */
}



.whatsapp-icon {
  width: 40px; /* Adjust the size of the WhatsApp icon */
  height: 40px; /* Adjust the size of the WhatsApp icon */
  padding: 10px; /* Adjust padding to center the icon */
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
