/* Testimonials.css */
.testimonials-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .testimonials-wrapper {
    overflow-x: hidden;
    width: 100%;
  }
  
  .testimonials-scroller {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  
  .testimonial-card {
    min-width: 100%; /* Ensure each card is the full width of the wrapper */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 10px;
    padding: 20px;
    /* ... more styles here */
  }
  
  .navigation-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    /* ... more styles here */
  }
  
  /* Responsive styles */
  
  