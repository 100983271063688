.trip-itinarary-mobile {
	max-width: 100%;
	margin: 40px auto;
}
.trip-itinarary-mobile h2 {
	margin-top: 40px;
	margin-bottom: 20px;
	font-size: 1.8rem;
	font-weight: 600;
	text-align: center;
}
.trip-itinarary-mobile h3 {
	margin-bottom: 20px;
	font-size: 1.6rem;
	font-weight: 600;
}

.trip-itinarary-mobile img{
	max-width: 100%; height: auto;
}
.collapse {
	overflow: scroll;
	margin-bottom: 40px;
	padding: 20px 0;
}

.collapse > :not(:last-child) {
	margin-bottom: 0px;
}

.collapse_summary div {
	margin: 0 !important;
}

.collapse_header {
	display: flex;
	align-items: center;
	height: 60px;
	font-size: 1.3rem;
}

.collapse_day {
	background: #f9f9f9;
	color: #000;
	font-size: 1.3rem;
	font-weight: 800;
	height: 100%;
	width: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.collapse_heading {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: flex-start;
	padding-left: 1rem;
}

.collapse_heading_text {
	font-size: 1.3rem;
	font-weight: 600;
	line-height: 140%;
}

.collapse_details {
	padding: 0;
}

.day-details {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: space-between;
	padding: 2rem 3rem;
}

.itinarary-content {
	width: 50%;
	padding-right: 60px;
}

.place-details {
	margin-bottom: 20px;
}

.place-description {
	font-size: 1.1rem;
	line-height: 1.6rem;
	letter-spacing: 0.2px;
	word-spacing: 1.3px;
}

.tags {
	margin-bottom: 1.2rem;
}
.tag {
	padding: 8px 0;
	font-size: 1rem;
	/* font-weight: 600; */
}
.tag span {
	font-size: 1rem;
	font-weight: 600;
}

.place-details h2 {
	margin-left: 12px;
	font-size: 1.6rem;
	font-weight: 400;
}

/* .place-details p {
	font-weight: 300;
} */

.itinarary-gallary {
	overflow-y: scroll;
	width: 50%;
}

@media (max-width: 480px) {
	.itinarary-content {
		width: 100%;
		padding: 0;
	}
	.itinarary-gallary {
		width: 100%;
		padding: 0;
	}
	.day-details {
		display: flex;
		flex-direction: column;
		padding: 24px 16px;
	}
	.place-details {
		padding: 0;
	}
}

@media (min-width: 480px) {
	.trip-itinarary-mobile {
		display: none;
	}
}