#contact {
  margin-top: 1rem;
}

.contact h2 {
  text-align: center;
  padding: 1rem;
  font-size: 3rem;
  font-weight: 400;
  word-spacing: 5px;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
}
.row {
  display: flex;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 5%;
}
.img {
  width: auto;
}
#contactimg {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
.form {
  width: 400px;
  height: 500px;
}

.contbox {
  width: 80%;
}
.callbox {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.2);
  background-color: #0f0f0f;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  color: #fff;
  cursor: pointer;
  width: 25%;
  min-width: 300px;
  margin: 1rem 0;
  padding: 1rem;
  display: flex;
  gap: 5%;
  font-family: "Urbanist", sans-serif;
}
.callbox i {
  color: #d5b15b;
}
#map {
  width: 100%;
}
#map:hover {
  transform: scale(120%);
  transition: 0.5s ease;
}
.callbox:hover {
  transform: translateX(2rem);
  transition: 0.3s ease;
}


@media (max-width: 600px) {
  .contact h2 {
    font-size: 2rem;
  }
  .img {
    width: 100%;
  }
  .row {
    width: 100%;
    flex-direction: column;
    margin: 0;
  }
  .form {
    width: 100%;
  }
  
}