.item-list {
    max-width: 1200px;
    width: 100%;
    padding: 2rem 3rem;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.packing-item {
    display: flex;
    gap: 20px;
    margin-bottom: 16px;
    letter-spacing: 0.2px;
}

.packing-item p{
    letter-spacing: 0.1px;
}

@media (max-width: 480px) {
    .packing-item p {
        font-size: 1rem;
    }
    .item-list {
        padding: 1rem 2rem;
    }
}