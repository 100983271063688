.why-choose-us {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
}

.section-title-choose {
	font-size: 1.8em;
	/* margin-bottom: 30px; */
  margin-top: 5%;
	text-align: center;
	font-weight: 600;
}

.services-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
  padding: 2%;
	/* gap: 50px; */
}

.service-card-why {
	width: 100%;
	max-width: 400px; /* You can adjust this as needed */
	/* border: 1px solid #ccc;
	border-radius: 8px; */
	padding: 20px;
	text-align: center;
	transition: box-shadow 0.3s;
}

/* .service-card:hover {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.icon {
	margin-bottom: 15px;
	font-size: 3rem;
	color: #d5b15b;
	/* Icon styles here */
}

.title-choose {
	font-size: 1.3rem;
	color: #333;
	margin-bottom: 10px;
}

.description {
	font-size: 1em;
	color: #666;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
	.why-choose-us {
		margin-bottom: 42px;
	}

	.services-container {
		flex-direction: column;
		align-items: center;
    padding-top: 15%;
		/* gap: 24px; */
	}
	.section-title-choose {
		font-size: 2em;
    margin-bottom: 12%;
    margin-top: 12%;
	}
  .service-card-why{
    margin-top: -12%;
  }
  .icon{
    font-size: 2rem;
  }
  .title-choose{
    font-size: 1rem;
  }
}
