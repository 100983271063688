.pricingTableContainer {
    max-width: 3000px;
    width: 100%;
    margin: auto;
    overflow-x: auto; /* Ensures table is responsive on small devices */

}

.pricingTable {
    width: 100%;
    border-collapse: collapse; /* Removes gaps between cells */
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.original-price-trip{
  text-decoration: line-through;
  font-size: .6rem;
}

.discounted-price-trip{
  color: #777;
}

.pricingTable th, .pricingTable td {  
    padding: 12px 16px; /* Spacing inside cells */
}

.pricingTable thead {
  background-color: #d5b15b; /* Example primary color */
    color: #000000;
}

.pricingTable tbody tr:nth-child(even) {
    background-color: #f2f2f2; /* Zebra striping for rows */
}

.pricingTable tbody tr:hover {
    background-color: #ddd; /* Hover effect for rows */
}

@media (max-width: 480px) {
  .pricingTableContainer {
    width: auto;
  }
  .pricingTable th, .pricingTable td {  
    font-size:0.7rem ;
    padding: 12px 16px; /* Spacing inside cells */
}
}