/* BlogsContainer.css */
.blogs-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 15px;
	box-sizing: border-box;
	padding: 6%;
	position: relative;
	-ms-flex-item-align: center;
}

/* .p-row {
	width: 90%;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5%;
	margin: 0 auto;
} */

.section-title-blog {
	font-size: 1.8em;
	/* margin-bottom: 54px; */
	text-align: center;
	padding-top: 5%;
	font-weight: 600;
}

@media (max-width: 768px) {
	.blogs-container {
		grid-template-columns: 1fr;
	}
}

@media (max-width: 480px) {
	.section-title {
		margin-bottom: 32px;
	}
}
