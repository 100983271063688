.card {
	font-family: "Open Sans", sans-serif;
	max-width: 350px;
	color: #333;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	/* margin: auto; */
	margin: 2%;
	display: flex;
	flex-direction: column;
}

.card-image {
	width: 100%;
	height: 200px;
	object-fit: cover;
}

.card-content {
	padding: 15px;
}

.card-pricing{
	padding-bottom: 4%;
}

.wrapword{
	white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
	word-wrap: break-word;
}

.card-title {
	font-size: 1.3rem;
	padding-top: 1rem;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.card-rating {
	background: #ffc107;
	border-radius: 4px;
	color: #fff;
	padding: 3px 6px;
	font-weight: bold;
	font-size: 14px;
	display: inline-block;
	margin-left: 10px;
}

/* .card-info {
    font-size: 15px;
  } */
.card-info {
	font-size: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.2rem 0 1rem 0;
}

.trip-data-item {
	display: flex;
	align-items: center;
	gap: 8px;
	font-weight: 800;
}

.card-description {
	font-size: 14px;
	margin: 10px 0;
}

.original-price {
	text-decoration: line-through;
	color: #777;
	margin-right: 8px;
}

.discounted-price {
	color: #d32f2f;
	font-weight: bold;
	font-size: 1.3rem;
}

.button_container {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex-grow: 1;
	padding-inline: 15px;
	padding-bottom: 12px;
}

.card-button {
	background-color: #d5b15b;
	color: black;
	border: none;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 4px;
	width: 100%;
}

.card-button:hover {
	background-color: #00ff80;
}

/* Media query for tablets */
@media (max-width: 768px) {
	.card {
		max-width: 500px;
	}
}

/* Media query for mobile devices */
@media (max-width: 480px) {
	.card {
		max-width: 100%;
		margin: 10px;
	}

	.card-title {
		font-size: 1.3rem;
	}

	.card-info {
		font-size: 13px;
	}

	.card-description {
		font-size: 12px;
	}

	.card-button {
		font-size: 14px;
	}
}
