.testimonials-section {
    text-align: center;
    padding: 5%;
  }

.testimonials-section h1{
    font-size: 50px;
    padding-bottom: 2%;
}
  
  .testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 40px;
  }
  
  .testimonial-card {
    width: calc(33.333% - 70px); /* Three cards per row minus gap */
    padding: 20px;
    background-color: #f9f9f9; /* Light background for the card */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .testimonial-text {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  
  .testimonial-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .testimonial-location {
    color: #666;
    font-size: 0.8em;
  }
  
  @media (max-width: 768px) {
    .testimonial-card {
      width: 100%; /* Full width on smaller screens */
    }
    .testimonials-section h1{
        font-size: 30px;
    }
  }
  