.scroll-container {
    display: flex;
    overflow-x: auto; /* Enables horizontal scrolling */
    gap: 20px; /* Space between cards */
    padding: 20px;
    scroll-behavior: smooth;
  }

  /* Hide scrollbar but allow scrolling for Webkit browsers like Chrome and Safari */
.scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for Firefox */
  .scroll-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  