.navbar_mobile_container {
	display: flex !important;
	padding-right: 24px !important;

	@media (min-width: 880px) {
		display: none !important;
	}

	@media (max-width: 480px) {
		padding-right: 8px !important;
	}
}

.icon_button {
	color: white !important;
}

.menu_icon {
	width: 30px !important;
	height: 30px !important;
}

.drawer {
	width: 300px !important;
	background-color: rgba(25, 25, 25) !important;
	color: white !important;
	padding: 24px !important;
}

.list > .list_item {
	margin-top: 18px !important;
}

.list > .list_item:first-child {
	margin-top: 0 !important;
}

.list_item {
	border-radius: 4px !important;
}

.list_item:hover {
	background-color: #d5b15b !important;
}

.package_list_item {
	padding-inline: 16px !important;
}
