.itinerary-container {
    font-family: "Montserrat", sans-serif;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    color: #333;
  }
  
  .itinerary-header h2 {
    color: #ffa500;
    font-size: 1.5rem;
  }
  
  .itinerary-header p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .itinerary-day-selection {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1rem;
  }
  
  .day-tab {
    padding: 5px 10px;
    border: none;
    background-color: #eee;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .day-tab.active {
    background-color: #ffa500;
    color: white;
  }
  
  .itinerary-content h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .itinerary-meta {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 1rem;
  }
  
  .itinerary-images img {
    width: calc(33.333% - 10px);
    margin-right: 15px;
    border-radius: 4px;
  }
  
  .itinerary-images img:last-child {
    margin-right: 0;
  }
  .itinerary-description {
    font-size: 0.9rem;
    line-height: 1.6;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .itinerary-day-selection {
      flex-direction: column;
      align-items: center;
    }
  
    .itinerary-images img {
      width: 100%;
      margin-bottom: 15px;
    }
  
    .itinerary-images img:last-child {
      margin-right: 0;
    }
  }
  
  @media (max-width: 480px) {
    .itinerary-content h3 {
      font-size: 1rem;
    }
  
    .itinerary-meta {
      font-size: 0.8rem;
    }
  
    .itinerary-description {
      font-size: 0.8rem;
    }
  }
  