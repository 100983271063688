.key-info-section {
    max-width: 1200px;
    width: 80%;
    margin: 40px auto;
}

.key-info-heading {
    /* margin-top: 40px; */
	margin-bottom: 40px;
	font-size: 1.8rem;
	font-weight: 600;
	text-align: center;
}

.list-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 80%;
    margin: 0 auto;
}
.list-container h2{
    margin-bottom: 40px;
    font-size: 2rem;
    text-align: center;
    font-weight: 400;
}

.tabs {
    margin-bottom: 2rem;
    /* padding: 20px 0; */
	display: flex;
	justify-content: center;
	font-size: 1rem;
}

@media (max-width: 768px) {
	.list-container {
		width: auto;
	}
}

@media (max-width: 480px) {
	.key-info-section {
		width: 100%;
		margin-inline: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.key-info-heading {
		font-size: 1.8rem;
	}

	.tabs {
		width: 100vw;
		overflow: scroll;
	}

	.list-container {
		margin-inline: 16px;
	}
}