.mail-button{
    position: fixed;
    bottom: 150px; /* Adjust the distance from bottom as per requirement */
    right: 6px; /* Adjust the distance from right as per requirement */
    z-index: 9999; /* Ensure it's above other content */
  }

  .mail-button button{
    outline: none;
    border: none;
    background-color: transparent;
  }
  
  
  .mailicon i {
    font-size: 40px;
    color: #d5b15b;
  }