.landing-component {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .slick-slide img {
    width: 100%;
    display: block;
    object-fit: cover;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 20px;
  }
  
  .explore-button {
    padding: 10px 20px;
    background-color: #ffa500;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .overlay-text h1 {
      font-size: 1.5em;
    }
    .overlay-text p {
      font-size: 1em;
    }
    .explore-button {
      font-size: 0.8em;
    }
  }
  