.tour-highlight {
    display: flex;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-container{
    display: flex;
    justify-content: center; /* Align buttons in the center horizontally */
    align-items: center;     /* Align buttons in the center vertically */
    gap: 10px;               /* Space between buttons */
    padding-top:20px;
}

.button-container button {
    padding: 1.54% 3%;      /* Padding inside buttons */
    font-size: 16px;         /* Button text size */
    cursor: pointer;         /* Cursor as pointer to indicate button */
    border: 1px solid #ccc;  /* Button border */
    background-color: #d5b15b; /* Button background color */
    transition: background-color 0.3s; /* Transition for hover effect */
    margin-top: -0.5%;
    border-radius: 3px;
  }

  .button-container button:hover {
    background-color: #00ff80; /* Slightly darker background on hover */
  }


.tour-image {
    flex: 1 1 50%;
    padding: 3rem 1rem 3rem 4rem;

}

.tour-image img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    display: block;
}

.tour-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 50%;
    padding: 20px 40px;
    padding: 3rem 3rem 3rem 1rem;
}

.title{
    margin-top: 0;
    font-size: 1.8rem;
    font-weight: 600;
    color: #d5b15b;
}

.tour-content h1 {
    margin-top: 0;
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
}

.tour-content p {
    margin: 5px 0;
    letter-spacing: 0.1px;
    line-height: 2rem;
}
.content-description {
    font-size: 1rem;
}

.alignButton{
    text-align: center;
    margin-top: 3%;
    margin-bottom: -1%;
}


.tour-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px 40px;
    margin-top: 40px;
}

.info-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 0.2px solid rgb(143, 138, 138);
}

.info-head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.info-item i {
    width: 12px;
    margin-right: 20px;
}

.info-item h3 {
    font-size: 1.3rem;
    font-weight: 400;
    color: #333;
}

.info-item p {
    font-size: 0.9rem;
    color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
    .tour-highlight {
        flex-direction: column;
    }

    .tour-image,
    .tour-content {
        flex: 1 1 100%;
        padding: 0;
    }
    .tour-image img {
        border-radius: 0;
    }

    .tour-content {
        padding: 20px 10px;
        width: 80%;
        margin: 0 auto;
    }
    .tour-info {
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        /* padding-left: 1rem; */
    }
    .button-container button{
        padding: 0% 6%;      /* Padding inside buttons */
    font-size: 16px;         /* Button text size */
    cursor: pointer;         /* Cursor as pointer to indicate button */
    border: 1px solid #ccc;  /* Button border */
    background-color: #d5b15b; /* Button background color */
    transition: background-color 0.3s; /* Transition for hover effect */
    margin-top: -0.5%;
    border-radius: 3px;
    }
}


@media (max-width: 480px) {
    .tour-content {
        width: 100%;
        margin: 0 auto;
        padding: 1rem 2rem;
    }
    .title{
        font-size: 1.4rem;    
    }
    .tour-content h1 {
        font-size: 1.4rem;
    }
    .tour-content p{
        width: 100%;
        margin: 0 auto;
    }
    .content-description {
        font-size: 1rem;
    }
    .tour-info {
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        gap: 10px;
    }

    .info-item {
        flex-direction: column;
        justify-content: start;
        margin-bottom: 2rem;
    }
    .info-head {
        margin-bottom: 0;
    }
    .info-head p{
        margin-top: 0;
    }

    .info-item h3,
    .info-item p {
        text-align: left;
    }

    .button-container {
        flex-direction: column; /* Stack buttons vertically on small screens */
        gap: 5px;
    }
    .button-container button{
        padding: 3.2% 6%;      /* Padding inside buttons */
    font-size: 16px;         /* Button text size */
    cursor: pointer;         /* Cursor as pointer to indicate button */
    border: 1px solid #ccc;  /* Button border */
    background-color: #d5b15b; /* Button background color */
    transition: background-color 0.3s; /* Transition for hover effect */
    margin-top: -0.5%;
    border-radius: 3px;
    }
    .alignButton{
        text-align: center;
        word-wrap: break-word;
        margin-top: 8%;
        margin-bottom: -2%;
    }
}