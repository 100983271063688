.testimonial-card {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	width: 5px;
	padding: 20px;
	background-color: #ffffff;
	border-radius: 20px;
}

.testimonial-image-wrapper {
	overflow: hidden;
	width: 100px;
	height: 100px;
	margin-top: -50px;
	border: 5px solid #ffffff;
	border-radius: 50%;
}

.testimonial-image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.testimonial-info {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	flex-grow: 1;
	text-align: center;
}

.testimonial-name {
	font-weight: bold;
}

.testimonial-position {
	font-size: 0.9em;
	color: #777;
}

.testimonial-text {
	font-style: italic;
	color: #333;
	margin-bottom: 0;
}

@media (max-width: 300px) {
	.testimonial-card {
		width: 90%;
	}
}
