.package-container {
	height: auto;
	margin-bottom: 60px;
}

.package-container h2 {
	/* padding: 3rem 4rem; */
	padding: 3%;
	font-size: 1.8rem;
	font-weight: 600;
	text-align: center;
}

.p-row {
	width: 90%;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5%;
	margin: 0 auto;
}

@media (max-width: 480px) {
	.p-row {
		width: 100%;
	}

	.package-container {
		margin-bottom: 42px;
	}

	.package-container h2 {
		font-size: 1.8rem;
		font-weight: 600;
		padding: 2%;
	}
}
