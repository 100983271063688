.trip-itinarary-desktop {
	max-width: 80%;
	margin: 40px auto;
}
.trip-itinarary-desktop h1 {
	margin-top: 40px;
	margin-bottom: 40px;
	font-size: 1.8rem;
	font-weight: 600;
	text-align: center;
}
.trip-itinarary-desktop h3 {
	margin-bottom: 20px;
	font-size: 1.6rem;
	font-weight: 600;
}
.stepper {
	font-size: 1rem;
	margin-bottom: 40px;
	padding: 20px 0;
}

.day-details {
	display: flex;
	justify-content: space-between;
	padding: 2rem 3rem;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.itinarary-content {
	width: 50%;
	padding-right: 60px;
}

.place-details {
	margin-bottom: 20px;
}

.place-heading {
	font-size: 1.3rem;
	font-weight: 400;
	margin-bottom: 1.5rem;
}
.place-heading span {
	font-weight: 600;
}
.place-description {
	font-size: 1rem;
	letter-spacing: 0.2px;
	line-height: 1.6rem;
	word-spacing: 1.3px;
}

.tags {
	margin-bottom: 1.2rem;
}
.tag {
	font-size: 1rem;
	/* font-weight: 500; */
	padding: 8px 0;
}
.tag span {
	font-size: 1rem;
	/* font-weight: 600; */
}

/* .place-details h2 {
	font-size: 1.6rem;
	font-weight: 400;
	margin-left: 12px;
} */

.place-details p {
	font-size: 1rem;
	/* font-weight: 300; */
}

.itinarary-gallary {
	width: 50%;
}

@media (max-width: 480px) {
	.trip-itinarary-desktop {
		display: none;
	}
}