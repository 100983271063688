/* BlogPage.css */
.blog-page {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.blog-page h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.blog-page h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 16px;
}

.blog-page p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
}

.blog-page ul {
  padding-left: 20px;
  margin-bottom: 16px;
}

.blog-page li {
  margin-bottom: 8px;
}
.blog-page img{
  max-width: 100%; height: auto;
}

.author-name {
  margin-top: 5%;
  margin-bottom: 5%;
}

@media (max-width: 768px) {
  .blog-page {
    padding: 10px;
  }

  .blog-page h1 {
    font-size: 22px;
  }

  .blog-page h2 {
    font-size: 18px;
  }

  .blog-page p, .blog-page li {
    font-size: 14px;
  }
  .blog-page .author-name h3{
    margin-top: 5%;
  }

  .blog-page img{
    max-width: 100%; height: auto;
  }
}
