.container-why {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  text-align: left;
  vertical-align: middle;
}

.box-why {
  flex: 1; /* Each box will take up equal space */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  padding: 5px; /* Space inside the box */
}

/* Optional: Add some styling for clarity */
.box1-why { font-size: 1.1rem; font-weight: 700;}
.box1-package{font-weight: 800;}
/* .box2-why { background-color: lightgreen; }  */

/* Ensure the boxes do not wrap on smaller screens */
@media (max-width: 600px) {
  .container-why {
    flex-direction: row;
  }
  .box-why {
    flex: 1; /* Keep this to ensure they still try to take equal space */
  }
}
