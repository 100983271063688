nav {
	width: 100%;
	z-index: 1;
	display: flex;
	background-color: rgba(0, 0, 0, 1);
	justify-content: space-between;
	align-items: center;
	height: 10vh;
}
/* 
@media (max-width: 390px) {
	.logo {
		padding-left: 0 !important;
	}
} */
