.inputbox .mail {
  text-transform: lowercase;
}
.form {
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
    text-align: left;
    width: 30%;
    padding: 3rem;
  }
.form .inputbox {
  margin: 1rem 0;
}
.form .inputbox h3 {
  text-transform: capitalize;
  font-family: "Urbanist", sans-serif;
  margin-bottom: 0.2rem;
  letter-spacing: 1px;
}
.form .inputbox input {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: "Urbanist", sans-serif;
  letter-spacing: 1px;
  padding: 0.5rem;
  border: 1px solid hsl(225, 10%, 45%);
}
.form .inputbox textarea {
  width: 100%;
  font-family: "Urbanist", sans-serif;
  word-spacing: 2px;
  padding: 1rem 2rem 2rem 0.5rem;
  resize: none;

  letter-spacing: 1px;
}
textarea::-webkit-scrollbar {
  width: 0.5rem;
}

textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: hsl(227, 10%, 45%);
  border-radius: 20px;
}

.subbtn {
  margin: 0.5rem 0;
  background-color: #d5b15b;
  font-family: "Urbanist", sans-serif;
  font-weight: 800;
  -webkit-appearance: none;
  border: none;
  outline: none;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  letter-spacing: 1px;
  padding: 1rem;
  -moz-appearance: none;
}
.subbtn:hover {
  background-color: #37474f;
  color: #fff;
  transition: 0.2s ease;
}
