.navbar_desktop_container {
	display: flex;
	justify-content: flex-end;
	width: 60vw;
}

nav div ul {
	display: flex;
	flex-grow: 1;
	justify-content: space-around;
	list-style-type: none;
}
nav div ul li {
	text-align: center;
	position: relative;
	display: inline-block;
	list-style: none;
}

nav div ul li a {
	display: block;
	text-decoration: none;
	padding: 1rem;
	border-radius: 5px;
	color: #fff;
	font-size: 1rem;
	letter-spacing: 2px;
	font-family: "Urbanist", sans-serif;
}
nav div ul li a:hover {
	background-color: #d5b15b;
	color: #0f0f0f;
	transition: 0.4s ease;
}
nav div ul ul {
	position: absolute;
	z-index: 100;
	padding: 0.5rem;
	display: none;
	width: 200%;
	background-color: #1b1b1b;
}
nav div ul ul li {
	text-align: left;
	width: 100%;
}
nav div ul ul li a {
	width: 100%;
	padding: 0.5rem;
	font-size: 0.9rem;
}
nav div ul li:hover > ul {
	display: block;
}
#homeli {
	color: #d5b15b;
	background-color: rgba(0, 0, 0, 0);
}

.logo {
	padding-left: 1rem;
}
.logo a span {
	color: #d5b15b;
}
.logo a {
	display: flex;
	padding-left: 1rem;
	text-decoration: none;
	letter-spacing: 2px;
	font-size: 2rem;
	color: #fff;
	font-family: "Urbanist", sans-serif;
}
.logo p {
	color: #fff;
	font-size: 0.7rem;
	word-spacing: 0.2rem;
	padding-left: 1rem;
	font-family: "Urbanist", sans-serif;
	letter-spacing: 4px;
	text-align: left;
}

.checkbtn {
	font-size: 30px;
	color: #d5b15b;
	cursor: pointer;
	display: none;
}
#check {
	display: none;
}
.fa-bars::before {
	content: "\f0c9";
}
@media all and (max-width: 880px) {
	.navbar_desktop_container {
		display: none;
	}

	nav {
		height: 10vh;
	}
	.checkbtn {
		display: block;
	}
	.checkbtn {
		padding-right: 20px;
	}
	#homeli {
		background-color: rgba(0, 0, 0, 0);
	}
	/* #drop {
    display: none;
    opacity: 0;
  } */
	.package-link {
		display: none;
		opacity: 0;
	}

	.list {
		display: block;
		width: 90%;
		position: absolute;
		z-index: 120;
		background: #1b1b1b;
		top: 10vh;
		height: 90vh;
		left: -100%;
		text-align: center;
		transition: all 0.5s;
	}

	.list li {
		width: 100%;

		height: 2rem;
		border-radius: 5px;
		text-align: center;
		margin-bottom: 10px;
		color: #fff;
		margin-top: 20px;
	}
	.list li a:hover {
		background-color: rgba(0, 0, 0, 0);
		transition: 0.2s ease;
		color: #d5b15b;
	}
	.list li a {
		font-size: 1rem;
	}
	nav div ul li {
		display: block;
	}
	nav div ul li:hover > ul {
		display: none;
	}

	#check:checked ~ ul {
		left: 0;
		top: 14vh;
		transition: all 0.5s ease-in;
	}
	#check:checked ~ .checkbtn i::before {
		content: "\f00d";
	}
}
