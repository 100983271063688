.packingListContainer {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem 3rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.packingListItem {
    margin-bottom: 30px;
}

.packingListItem h3 {
    color: #333;
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 700;
}

.packingListItem ul {
    list-style-type: disc;
    padding-left: 20px;
}

.packingListItem li {
    color: #666;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 5px;
}

@media (max-width: 768px) {
    .packingListItem h3 {
        font-size: 0.75rem;
    }

    .packingListItem ul {
        padding-left: 15px;
    }

    .packingListItem li {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .packingListItem h3 {
        font-size: 1.1rem;
    }

    .packingListItem li {
        font-size: 0.85rem;
    }
}
